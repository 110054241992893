<template>
    <section>
        <div>
            <!-- CONTENT -->
            <div class="container">
                <p class="referenceTitre padding-top-100-px">PDAAU</p>
                <p class="referenceTitre titre-pdaau-texte">
                    Plan départemental d'acheminement des appels d'urgence
                </p>
                <div class="inline-flex-center">
                    Année du référentiel des communes :
                    {{ this.$store.state.anneeReference }}
                </div>
                <div class="inline-flex-center padding-top-70-px">
                    <form
                        @submit.prevent="telechargementPubliPdaau"
                        class="right_block inline-flex-column-center">
                        <button
                            type="submit"
                            class="
                                bouton-telechargement
                                no-border
                                margin-bottom-10-px
                            "
                            :disabled="buttonDisabled">
                            <img
                                src="../../assets/img/tnaau/Icon_telechargement.svg"
                                class="margin-right-10-px" />
                            Télécharger le PDAAU
                        </button>
                    </form>
                    <div>
                        <form
                            v-if="isIntegration"
                            @submit.prevent="IntegreePdaau"
                            class="right_block inline-flex-column-center">
                            <button
                                type="submit"
                                class="
                                    bouton-telechargement
                                    no-border
                                    margin-bottom-10-px
                                "
                                :disabled="isIntegred">
                                Je reconnais avoir intégré les modifications du
                                PDAAU
                            </button>
                        </form>
                    </div>
                </div>
                <div class="texte15 inline-flex-center">
                    <div class="date-publication">
                        <div>Date de publication</div>
                        <div class="bold" id="datePublication"></div>
                    </div>
                </div>
                <div
                    class="
                        uppercase
                        titre-tableau
                        font-titre-tableau
                        inline-flex-center
                        padding-top-20-px
                    ">
                    CAAU
                </div>
                <div
                    class="no-select inline-flex-center"
                    v-if="this.lastModifPublicationCaau.length > 0">
                    <table>
                        <tr>
                            <td class="headerId">ID CAAU</td>
                            <td class="headerdata cornerLeft">N° court</td>
                            <td class="headerdata">Nouveau n° long</td>
                            <td class="headerdata">À compter du</td>
                            <td class="headerdata">Ancien numéro long</td>
                            <td class="headerdata cornerRight">Jusqu'au</td>
                        </tr>
                        <tr
                            v-for="item in this.lastModifPublicationCaau"
                            :key="item.id">
                            <td class="subHeader">
                                {{ item.identifiant_caau }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_court }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_long_nouveau }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_nouveau_acompter_du }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_long_ancien }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_ancien_jusqu_au }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    v-if="this.lastModifPublicationCaau.length == 0"
                    class="font-titre-tableau text-align-center">
                    Aucune modification n'a été apportée pour cette publication
                </div>
                <div
                    class="
                        inline-flex-center
                        titre-tableau
                        font-titre-tableau
                        padding-top-20-px padding-bottom-5-px
                    ">
                    PDAA
                </div>
                <div
                    v-if="this.lastModifPublicationPdau.length > 0"
                    class="font-titre-tableau inline-flex-center no-select">
                    <table>
                        <tr>
                            <td class="headerId">Commune</td>
                            <td class="headerdata cornerLeft">N° court</td>
                            <td class="headerdata">Nouveau CAAU</td>
                            <td class="headerdata">À compter du</td>
                            <td class="headerdata">Ancien CAAU</td>
                            <td class="headerdata cornerRight">Jusqu'au</td>
                        </tr>
                        <tr
                            v-for="item in this.lastModifPublicationPdau"
                            :key="item.id">
                            <td class="subHeader alignLeft">
                                {{ item.commune }}
                            </td>
                            <td class="subHeader">
                                {{ item.numero_court }}
                            </td>
                            <td class="subHeader">
                                {{ item.identifiant_caau_nouveau }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_nouveau_a_compter_du }}
                            </td>
                            <td class="subHeader">
                                {{ item.identifiant_caau_ancien }}
                            </td>
                            <td class="subHeader">
                                {{ item.date_ancien_jusqu_au }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div
                    v-if="this.lastModifPublicationPdau.length == 0"
                    class="font-titre-tableau text-align-center">
                    Aucune modification n'a été apportée pour cette publication
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import * as eventTypes from '@/bus/event-types'
import EventBus from '@/bus'
import download from 'downloadjs'
import { notificationLogout } from '../../tools/authent'

export default {
    name: 'HomeOce',
    data() {
        return {
            idDep: '',
            idPublication: '',
            codeDep: '',
            codeIntegration: '',
            codeOce: '',
            isIntegred: false,
            buttonDisabled: false,
            lastModifPublicationCaau: [],
            lastModifPublicationPdau: []
        }
    },
    methods: {
        reset() {
            this.id_publication = ''
            this.date_publication = ''
            this.pourcentOperateursNonPrincipaux = ''
            this.pourcentOperateursPrincipaux = ''
            this.pourcentageIntegrationOperateursPrincipaux = ''
            this.pourcentageIntegrationOperateursNonPrincipaux = ''
        },
        goto(url) {
            window.location.href = url
        },
        getInfoPdaau() {
            var dateRecuperation = ''
            var dateIntegration = ''
            if (this.idDep) {
                var datePublication = document.getElementById('datePublication')
                axios
                    .get('/publication/' + this.idDep, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data !== null) {
                                datePublication.textContent =
                                    response.data.date_publication

                                this.idPublication =
                                    response.data.id_publication
                                dateRecuperation =
                                    response.data.date_recuperation
                                dateIntegration = response.data.date_integration
                                this.codeIntegration =
                                    response.data.code_integration
                                this.codeOce = response.data.code_oce
                                this.buttonDisabled = false
                                this.getModifPdaauPublies(
                                    response.data.id_publication
                                )
                            } else {
                                datePublication.textContent =
                                    'Aucune publication disponible pour ce département.'
                            }
                            if (
                                dateRecuperation != '' &&
                                dateIntegration == ''
                            ) {
                                this.isIntegred = false
                            } else {
                                this.isIntegred = true
                            }
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de récupérer les infos du pdaau",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        if (e.response.status === 404) {
                            datePublication.textContent =
                                'Aucune publication disponible pour ce département.'
                            this.isIntegred = true
                            this.buttonDisabled = true
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        telechargementPubliPdaau() {
            if (this.idPublication) {
                axios
                    .get('/publicationzip/', {
                        params: {
                            idDepartement: this.idDep,
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                        responseType: 'blob',
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            var filenameBrut =
                                '' +
                                response.headers['content-disposition'].match(
                                    /filename="(.*)"$/g
                                )
                            filenameBrut = filenameBrut.replace('filename=', '')
                            var filename = filenameBrut.split('"').join('')
                            download(
                                new Blob([response.data]),
                                filename,
                                'application/octet-stream'
                            )
                            this.isIntegred = false
                            this.$notify({
                                group: 'general-notification',
                                title: 'Téléchargement',
                                text: 'PDAAU téléchargé avec succès.',
                                type: 'success',
                                duration: 5000,
                            })
                        } else {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Erreur de connexion',
                                text: "Un problème est survenu durant le téléchargement de l'archive",
                                type: 'warn',
                                duration: 5000,
                            })
                        }
                    })
                    .catch((e) => {
                        if (e.response.status === 403) {
                            this.$notify({
                                group: 'general-notification',
                                title: 'Interdiction',
                                text: "Vous n'avez pas l'autorisation de télécharger la publication",
                                type: 'error',
                                duration: 5000,
                            })
                        }
                        notificationLogout(this, e)
                    })
            }
        },
        IntegreePdaau() {
            axios
                .post(
                    '/pdaau/integration?code=' +
                        this.codeIntegration +
                        '&dep=' +
                        this.$store.state.dptSelect.codeInsee +
                        '&oce=' +
                        this.codeOce,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization:
                                'Bearer ' + this.$store.state.user.token,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        this.isIntegred = false
                        this.buttonEnabled = true
                        this.$notify({
                            group: 'general-notification',
                            title: 'Intégration',
                            text: 'Données intégrées.',
                            type: 'success',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    if (e.response.status === 401) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Non autorisé',
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    if (e.response.status === 500) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur',
                            text: "Le code n'est pas valide",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
        getModifPdaauPublies(idPublication) {
            this.lastModifPublicationCaau = []
            this.lastModifPublicationPdau = []
            axios
                .get('/publication/archive/' + idPublication, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.user.token,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (
                            response.data.message === null ||
                            response.data.message === undefined
                        ) {
                            this.lastModifPublicationCaau = response.data.caau
                            this.lastModifPublicationPdau = response.data.pdaau
                        } else {
                            this.reset()
                        }
                    } else {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Erreur de connexion',
                            text: 'Un problème est survenu durant la récupération des infos des pdaau publiés',
                            type: 'warn',
                            duration: 5000,
                        })
                    }
                })
                .catch((e) => {
                    this.reset()
                    if (e.response.status === 403) {
                        this.$notify({
                            group: 'general-notification',
                            title: 'Interdiction',
                            text: "Vous n'avez pas l'autorisation de récupérer les infos des pdaau publiés",
                            type: 'error',
                            duration: 5000,
                        })
                    }
                    notificationLogout(this, e)
                })
        },
    },
    mounted() {
        // On met à jour l'identifiant du département
        this.idDep = this.$store.state.dptSelect.idDepartement

        // get l'année du référentiel des communes
        axios
            .get('/config/annee', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.user.token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    this.$store.state.anneeReference =
                        response.data.anneeReference
                    this.$store.commit('saveStore')
                }
            })
            .catch((e) => {
                notificationLogout(this, e)
            })

        // On récupère les informations du pdaau
        this.getInfoPdaau()
        // Permet de savoir si le département a été changé
        EventBus.$on(eventTypes.DEPT_CHANGE, (args) => {
            let obj = args.departement
            // On met à jour l'identifiant du département qui va être transmis à l'api
            this.idDep = obj.idDepartement

            // On réinitialise les informations liées au pdaau
            var datePublication = document.getElementById('datePublication')
            datePublication.textContent = ''
            this.idPublication = ''

            // On récupère les informations du pdaau
            this.getInfoPdaau()
        })
    },
    computed: {
        isLogged() {
            return this.$store.state.user.fullLogged
        },
        isIntegration() {
            return this.$store.state.integration
        },
    },
    destroyed() {
        EventBus.$off(eventTypes.DEPT_CHANGE)
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.texte15 {
    font-family: 'Marianne Light';
    font-size: 15px;
    width: 100%;
}

.bouton-telechargement {
    display: inline-flex;
    color: #ffffff;
    font-family: 'Marianne Medium';
    font-size: 14px;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 400px;
    background-color: red;
    border-radius: 70px;
    padding: 30px 75px;
    cursor: pointer;
    margin: 15px;
}

button:disabled {
    font-size: 14px;
    cursor: not-allowed;
    background-color: #d8d8d8;
}

.date-publication {
    padding-top: 35px;
    text-align: center;
}

.referenceTitre {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 32px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
}

.titre-pdaau-texte {
    font-family: 'Marianne Medium';
    height: 40px;
    text-align: center;
}
</style>
